import React, { useState } from 'react';
import axios from 'axios';

const ContactForm = props => {
  const { handleSubmit, pristine, reset, submitting, classes } = props;
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [SuccessMsg, setSuccessMsg] = useState(null);

  function submitDATA(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (
      name.length <= 4 ||
      number.length <= 6 ||
      email.length <= 9 ||
      message.length <= 5
    ) {
      setSuccessMsg('fieldsMissing');
      setTimeout(() => {
        setSuccessMsg(null);
      }, 6000);
    } else {
      // Updated endpoint: using '/send-contact-email'
      const url = process.env.GATSBY_API_URL + '/send-contact-email';
      // Update payload: send the phone number as "phone" and question as "question"
      const data = {
        name: name,
        phone: number,
        email: email,
        question: message,
      };
      const headers = {
        'Access-Control-Allow-Origin': '*',
      };

      axios.post(url, data, { headers: headers })
        .then(res => {
          if (res.data === 'Success') {
            setName('');
            setNumber('');
            setEmail('');
            setMessage('');
            setSuccessMsg(true);
          } else {
            setSuccessMsg(false);
          }
        })
        .catch(err => {
          console.error(err);
          setSuccessMsg(false);
        });
    }
  }

  function handleChange(event) {
    setMessage(event.target.value);
  }

  return (
    <section className="section2 clearfix">
      <div className="col2 column1 first">
        <div className="sec2contactform">
          <form>
            <div className="clearfix">
              <input
                className="col2 first"
                title="Your Full Name"
                required
                id="name"
                value={name}
                name="name"
                type="text"
                placeholder="Name *"
                onChange={event => setName(event.target.value)}
              />
              <input
                className="col2 last"
                required
                id="number"
                value={number}
                title="Your Phone Number"
                name="number"
                type="text"
                placeholder="Phone Number *"
                onChange={event => setNumber(event.target.value)}
              />
            </div>
            <div className="clearfix">
              <input
                style={{ width: '100%' }}
                required
                className="col2 first"
                title="Your Email Address"
                value={email}
                name="email"
                type="email"
                placeholder="Email *"
                onChange={event => setEmail(event.target.value)}
              />
            </div>
            <div className="clearfix">
              <textarea
                name="textarea"
                required
                cols="30"
                rows="7"
                placeholder="Enter your question *"
                value={message}
                title="Your Question"
                onChange={handleChange}
              />
            </div>
            <div className="clearfix">
              <button
                style={{ marginTop: '10px' }}
                className="buttonContact btn--outline btn--medium"
                onClick={submitDATA}
              >
                SEND
              </button>
            </div>
          </form>
          {SuccessMsg === true ? (
            <h3>Thank you for the question. We will get back to you shortly</h3>
          ) : (
            ''
          )}
          {SuccessMsg === false ? (
            <h3>Some error occured! Please try again!</h3>
          ) : (
            ''
          )}
          {SuccessMsg === 'fieldsMissing' ? (
            <h3 style={{ color: 'red' }}>Please fill all mandatory fields!</h3>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
