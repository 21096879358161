import React from 'react';
import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import ContactForm from '../components/common/contactform';
import { Helmet } from "react-helmet";

const Help = () => {
  return (
    <Layout>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes" />
        <meta name="author" content="LaundryToGo - We Make Laundry Easy" />
        <meta name="description" content="Frequently Asked Questions About LaundryToGo Service." />
        <title>LaundryToGo - Help</title>
      </Helmet>
      <div id="wrapper" style={{ color: "#000" }}>
        <div className="container" style={{ padding: "80px 20px", display: "flex", justifyContent: "center" }}>
          <div className="row">
            <div className="col-md-12" style={{ padding: "0px" }}>
              <h3 className="help-heading contactForm contactHeading">Questions?</h3>
              <ContactForm />
            </div>
          </div>
        </div>
        {/* Start FAQ Section */}
        <div className="help-wrapper" style={{ background: "#000", color: "#fff" }}>
          {/* FAQ Title */}
          <div className="container faq-container" style={{ padding: "10px 20px 0px 20px", marginBottom: "0px" }}>
            <div className="row">
              <div className="col-md-8">
                <h3 className="help-heading title" style={{ color: "#fff", marginBottom: "5px" }}>FAQ</h3>
              </div>
            </div>
          </div>
          {/* FAQ Item 1: How does your laundry service work? */}
          <div className="container faq-container" style={{ padding: "5px 20px", marginTop: "0px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  How does your laundry service work?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We offer a convenient door-to-door wash and fold service. Simply schedule a pickup online or by phone, and we’ll collect your laundry right from your doorstep. We operate 7 days a week with morning pickups and afternoon/evening deliveries—generally, the earlier your laundry is picked up, the sooner it’s returned.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 2: How does payment work? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  How does payment work?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Once your laundry is processed, we send you an invoice via email and SMS that includes a secure payment link. You can pay using any major credit or debit card.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 3: What type of clothes do you clean? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What type of clothes do you clean?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We clean all items that are safe for a washer and dryer. This includes everyday clothing, bedding, comforters, towels, bathroom mats, and more. Please note, we do not service items with signs of bedbugs or ticks, designer clothing, or items that require dry cleaning or ironing.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 4: What is your pricing? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What is your pricing?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Our pricing is straightforward: we charge $1.89 per pound (your laundry is accurately weighed for fair billing) plus a flat fee of $9.99 for round-trip pickup and delivery. For example, if your laundry weighs around 30 lbs, your total cost will be based on the weight plus the delivery fee.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 5: What services do you offer? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What services do you offer?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We provide a wash and fold service only. We do not offer ironing or dry cleaning, nor do we inspect individual garment labels. If any items require special handling (for example, "do not dry"), please include those instructions when booking. We also do not service designer clothing.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 6: Can you remove stains? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  Can you remove stains?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We do our best to treat stains during our wash and fold process; however, we cannot guarantee complete stain removal as the nature and severity of stains can vary. If you have a specific stain you'd like us to address, please let us know at the time of booking so we can plan accordingly.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 7: Do you sort laundry by darks and lights? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  Do you sort laundry by darks and lights?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Yes, sorting by darks and lights is part of our standard process. However, if you prefer a specific way to separate your items, please indicate your preference when booking. We default to cold water settings to help protect your garments.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 8: What temperature do you wash my clothes in? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What temperature do you wash my clothes in?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We default to cold water washes to protect your garments from damage, unless you specify otherwise at the time of booking.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 9: How do you handle delicate items? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  How do you handle delicate items?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  For delicate items that require no dryer and should be hung to dry, please inform us at the time of booking by including a note in the 'special instructions' on the scheduling form or by contacting us directly. We take extra care with delicate items and ensure they are processed accordingly.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 10: What should I put my laundry in? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What should I put my laundry in?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We recommend using laundry bags if available. They can be securely tied to keep your clothes together, our labels adhere well to them, and they fit perfectly in our vehicle.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 11: How is my laundry processed? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  How is my laundry processed?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Each order is handled individually using strict protocols to ensure your laundry is cared for from start to finish. From washing, drying, and folding to secure packaging for delivery, your items are treated with the utmost care.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 12: Can I specify special instructions? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  Can I specify special instructions?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Absolutely! Whether it’s treating delicate items, using hypoallergenic detergent, or specifying a particular sort order, please include any special instructions when booking your service.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 13: Where do my clothes get picked up and dropped off? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  Where do my clothes get picked up and dropped off?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  We come to you. Please provide your full address and any additional details during scheduling. Our driver will call to confirm the details before pickup and drop-off. Contactless service is available.
                </p>
              </div>
            </div>
          </div>
          {/* FAQ Item 14: What if I have additional questions or concerns? */}
          <div className="container faq-container" style={{ padding: "5px 20px" }}>
            <div className="row">
              <div className="col-md-8">
                <h5 className="help-heading question" style={{ color: "#fff" }}>
                  What if I have additional questions or concerns?
                </h5>
              </div>
              <div className="col-md-8 answer">
                <p>
                  Our customer support team is here to help. If you need further information or have special requests, feel free to contact us via phone or text.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End FAQ Section */}
      </div>
      <Footer bg={"white"} />
    </Layout>
  );
};

export default Help;
